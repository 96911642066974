import { useEffect } from 'react';
import { getQueryStringValue } from './services/Api';
import i18n from './services/i18n';
import FileUploaderContainer from './components/uploaderContainer/FileUploaderContainer';
import "./App.scss"
import { Route, Switch } from 'react-router-dom';
import Success from './components/success/Success';

function App() {

  useEffect(() => {
    document.title = "Send documents";
    const langFromQuery =
      getQueryStringValue("lang") || getQueryStringValue("language");
    i18n.changeLanguage(langFromQuery);
  }, [])

  return <>
    <div className='App'>
      <Switch>
        <Route path="/" exact >
          <FileUploaderContainer />
        </Route>
        <Route path="/success">
          <Success />
        </Route>
      </Switch>
    </div>
  </>
}

export default App;
