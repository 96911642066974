import axios, { AxiosError } from "axios";

function paramValidator(token: string, magazineId: string | number) {
    if (!token) throw new Error("no token");
    if (!magazineId) throw new Error("no magazineId");
}

export function getQueryStringValue(key: string) {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const paramValue = url.searchParams.get(key);
    return paramValue || "";
}

export const uploadFiles = async (
    files: File[],
    documentType: string,
    token: string,
    magazineId: number
) => {
    try {
        for (let i = 0, len = files.length; i < len; i++) {
            const formData = new FormData();

            paramValidator(token, magazineId)

            formData.append("attachedFile", files[i] as Blob);
            formData.append("documentType", documentType)
            const url = `/api/uploads/${magazineId}`;

            await axios.post(
                url,
                formData,
                {
                    headers: {
                        Authorization: token,
                        "x-magazine-id": magazineId,
                    },
                }
            );
            console.log(`file ${i + 1} successfully sent`);
        }
    } catch (error: any) {
        if (error instanceof AxiosError && error.response?.status === 403) {
            throw new Error("something went wrong with the plugin, please contact support.")
        }
        if (error instanceof AxiosError && error.response?.status === 413) {
            throw new Error("The file you tried to upload is too large. (Max file size: 20 MB)")
        }
        throw error;
    }
};