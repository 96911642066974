import React from 'react';

function IconAttachment() {
  return <div className="iconAttachment">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Vector" d="M43 0H5C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5L0 43C0 43.6566 0.129329 44.3068 0.380602 44.9134C0.631876 45.52 1.00017 46.0712 1.46447 46.5355C1.92876 46.9998 2.47995 47.3681 3.08658 47.6194C3.69321 47.8707 4.34339 48 5 48H34C34.5295 47.9926 35.0367 47.7855 35.42 47.42L47.42 35.42C47.779 35.0326 47.9851 34.528 48 34V5C48 4.34339 47.8707 3.69321 47.6194 3.08658C47.3681 2.47995 46.9998 1.92876 46.5355 1.46447C46.0712 1.00017 45.52 0.631876 44.9134 0.380602C44.3068 0.129329 43.6566 0 43 0ZM44 31C44 31.2652 43.8946 31.5196 43.7071 31.7071C43.5196 31.8946 43.2652 32 43 32H36C34.9391 32 33.9217 32.4214 33.1716 33.1716C32.4214 33.9217 32 34.9391 32 36V43C32 43.2652 31.8946 43.5196 31.7071 43.7071C31.5196 43.8946 31.2652 44 31 44H5C4.73478 44 4.48043 43.8946 4.29289 43.7071C4.10536 43.5196 4 43.2652 4 43V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H43C43.2652 4 43.5196 4.10536 43.7071 4.29289C43.8946 4.48043 44 4.73478 44 5V31Z" fill="#6B6B6B" />
      </g>
    </svg>
  </div>
}

export default IconAttachment;
