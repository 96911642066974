import * as React from "react";
import clsx from "clsx";
import { Icon } from "@o4c/plugin-components";
import Alert from "../components/alert/AlertComponent";

const AlertContext = React.createContext({});

function alertReducer(state: any, action: any) {
	switch (action.type) {
		case "show": {
			return {
				visible: true,
				message: action.payload,
				onAccept: action.onAccept || null,
			};
		}
		case "hide": {
			return {
				visible: false,
				message: "",
				onAccept: null,
			};
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function AlertProvider(props: any) {
	const [state, dispatch] = React.useReducer(alertReducer, {
		visible: false,
		message: "",
		onAccept: null,
	});
	const [isDisplayAlertVisibleDelayed, setIsDisplayAlertVisibleDelayed] =
		React.useState(false);
	const value = { state, dispatch };
	// const { t } = useTranslation();

	React.useEffect(() => {
		let timeoutId: NodeJS.Timeout | undefined;

		if (value.state.visible) {
			timeoutId = setTimeout(() => {
				setIsDisplayAlertVisibleDelayed(value.state.visible);
			}, 300);
		}

		if (timeoutId) {
			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [value.state, setIsDisplayAlertVisibleDelayed]);

	const onAcceptPrompt = () => {
		value.state.onAccept instanceof Function && value.state.onAccept();
		dispatch({ type: "hide", payload: "", onAccept: null });
	};

	return (
		<>
			<AlertContext.Provider value={value}>
				{props.children}
				<Alert className="AlertContainer" show={value.state.visible}>
					<div
						className={clsx(
							"contentWrapper",
							isDisplayAlertVisibleDelayed && "show"
						)}>
						<div className="header">
							<Icon name="alert" color="negative" rev="" />
							<p>{"Discard changes"}</p>
							<Icon
								rev=""
								name="close-bold"
								color="primary"
								onClick={() => {
									dispatch({ type: "hide", payload: "", onAccept: null });
								}}
							/>
						</div>
						<div className="content">{value.state.message}</div>
						<div className="footer">
							{!value.state.onAccept && (
								<button
									className="o4c button negative solid"
									onClick={() => {
										dispatch({ type: "hide", payload: "", onAccept: null });
									}}>
									Yes
								</button>
							)}
							{value.state.onAccept && (
								<>
									<button
										className="o4c button default outline"
										onClick={() => {
											dispatch({ type: "hide", payload: "", onAccept: null });
										}}>
										No
									</button>
									<button
										className="o4c button negative solid"
										onClick={() => {
											onAcceptPrompt();
										}}>
										Yes
									</button>
								</>
							)}
						</div>
					</div>
				</Alert>
			</AlertContext.Provider>
		</>
	);
}

function useAlertStateContext() {
	return React.useContext(AlertContext as any);
}

export { AlertProvider, useAlertStateContext };
