import './alert.scss';

function Alert(props: { children: any, show: boolean, className?: string }) {
  return (<div className={props.className || ''}>
    {
      props.show &&
      <div className="Alert">
        {props.children}
      </div>
    }
  </div>
  );
}

export default Alert;