import { FunctionComponent } from 'react';
import successStyle from "./success.module.scss"
import { Link } from 'react-router-dom';

interface ISuccessProps { }

const Success: FunctionComponent<ISuccessProps> = ({ }) => {
    return (
        <div className={successStyle.container}>
            <div className={successStyle.text}>Your document was successfully sent.</div>
            <div>
                <Link className='o4c button inline default outline' to="/">send a new document</Link>
            </div>
        </div>
    );
};

export default Success;