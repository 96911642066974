import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { useAlertStateContext } from '../../services/AlertProvider';
import { useHistory } from 'react-router-dom';
import { uploadFiles } from '../../services/Api';
import { UploaderFileData } from '../../interfaces';
import sendButtonStyle from "./sendButton.module.scss"
import { getQueryStringValue } from '../../services/Api';

interface ISendButtonProps {
    setLoading: Dispatch<SetStateAction<boolean>>;
    setFiles: Dispatch<SetStateAction<UploaderFileData[]>>;
    files: UploaderFileData[];
    documentType: string
}

const SendButton: FunctionComponent<ISendButtonProps> = ({
    setLoading,
    files,
    documentType,
    setFiles
}) => {
    const alertContext = useAlertStateContext() as any;
    const [sendingError, setSendingError] = useState<boolean>(false)
    const history = useHistory()

    const handleSendFile = async () => {
        setLoading(true)
        try {
            const token = getQueryStringValue("token");
            const magazineId = Number(getQueryStringValue("magazine"));
            if (!files.length) {
                alertContext.dispatch({
                    type: "show",
                    payload: "No file uploaded",
                });
                return;
            }
            const filesToUpload = files.map((uploaderFile: UploaderFileData) => uploaderFile.file)
            await uploadFiles(filesToUpload, documentType, token ?? "", magazineId)
            history.push("/success")
            setSendingError(false);
            setFiles([])
        } catch (error: any) {
            alertContext.dispatch({
                type: "show",
                payload: error.message,
            });
            setSendingError(true)
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <div className={sendButtonStyle.sendButtonContainer}>
                {sendingError ? <div className={sendButtonStyle.sendingErrorText}>Failed to send, please re-send or try again later.</div> : null}
                <button
                    className='o4c button positive block default solid'
                    onClick={handleSendFile}
                >
                    {sendingError ? "re-send" : "send"}
                </button>
            </div>
        </>
    );
};

export default SendButton;