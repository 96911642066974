import React from "react"

function IconDelete() {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Group">
				<path id="Vector" d="M12.9681 5.75H3.03146C2.98505 5.74981 2.9391 5.75932 2.89657 5.77792C2.85404 5.79651 2.81587 5.82379 2.78449 5.85799C2.75311 5.89219 2.72922 5.93257 2.71435 5.97654C2.69948 6.02052 2.69395 6.06711 2.69813 6.11334L3.55613 15.5373C3.58625 15.8687 3.73919 16.1769 3.98491 16.4013C4.23063 16.6256 4.55137 16.75 4.88413 16.75H11.1155C11.4482 16.75 11.769 16.6256 12.0147 16.4013C12.2604 16.1769 12.4134 15.8687 12.4435 15.5373L13.3001 6.11334C13.3043 6.06724 13.2987 6.0208 13.2839 5.97695C13.2691 5.9331 13.2454 5.89282 13.2141 5.85867C13.1829 5.82446 13.1449 5.79714 13.1026 5.77843C13.0602 5.75972 13.0144 5.75004 12.9681 5.75ZM6.8348 14.4167C6.8348 14.5493 6.78212 14.6765 6.68835 14.7702C6.59458 14.864 6.46741 14.9167 6.3348 14.9167C6.20219 14.9167 6.07501 14.864 5.98124 14.7702C5.88748 14.6765 5.8348 14.5493 5.8348 14.4167V8.41667C5.8348 8.28406 5.88748 8.15688 5.98124 8.06312C6.07501 7.96935 6.20219 7.91667 6.3348 7.91667C6.46741 7.91667 6.59458 7.96935 6.68835 8.06312C6.78212 8.15688 6.8348 8.28406 6.8348 8.41667V14.4167ZM10.1681 14.4167C10.1681 14.5493 10.1155 14.6765 10.0217 14.7702C9.92792 14.864 9.80074 14.9167 9.66813 14.9167C9.53552 14.9167 9.40835 14.864 9.31458 14.7702C9.22081 14.6765 9.16813 14.5493 9.16813 14.4167V8.41667C9.16813 8.28406 9.22081 8.15688 9.31458 8.06312C9.40835 7.96935 9.53552 7.91667 9.66813 7.91667C9.80074 7.91667 9.92792 7.96935 10.0217 8.06312C10.1155 8.15688 10.1681 8.28406 10.1681 8.41667V14.4167Z" fill="#DB0000" />
				<path id="Vector_2" d="M14.6667 3.41667H11.5001C11.4559 3.41667 11.4135 3.39911 11.3822 3.36785C11.351 3.3366 11.3334 3.2942 11.3334 3.25V2.41667C11.3334 1.97464 11.1578 1.55072 10.8453 1.23816C10.5327 0.925595 10.1088 0.75 9.66675 0.75L6.33342 0.75C5.89139 0.75 5.46746 0.925595 5.1549 1.23816C4.84234 1.55072 4.66675 1.97464 4.66675 2.41667V3.25C4.66675 3.2942 4.64919 3.3366 4.61793 3.36785C4.58668 3.39911 4.54428 3.41667 4.50008 3.41667H1.33341C1.1566 3.41667 0.987034 3.4869 0.86201 3.61193C0.736986 3.73695 0.666748 3.90652 0.666748 4.08333C0.666748 4.26014 0.736986 4.42971 0.86201 4.55474C0.987034 4.67976 1.1566 4.75 1.33341 4.75H14.6667C14.8436 4.75 15.0131 4.67976 15.1382 4.55474C15.2632 4.42971 15.3334 4.26014 15.3334 4.08333C15.3334 3.90652 15.2632 3.73695 15.1382 3.61193C15.0131 3.4869 14.8436 3.41667 14.6667 3.41667ZM6.00008 3.25V2.41667C6.00008 2.32826 6.0352 2.24348 6.09771 2.18096C6.16022 2.11845 6.24501 2.08333 6.33342 2.08333H9.66675C9.75515 2.08333 9.83994 2.11845 9.90245 2.18096C9.96496 2.24348 10.0001 2.32826 10.0001 2.41667V3.25C10.0001 3.2942 9.98252 3.3366 9.95127 3.36785C9.92001 3.39911 9.87762 3.41667 9.83342 3.41667H6.16675C6.12255 3.41667 6.08015 3.39911 6.0489 3.36785C6.01764 3.3366 6.00008 3.2942 6.00008 3.25Z" fill="#DB0000" />
			</g>
		</svg>

	)
}

export default IconDelete
