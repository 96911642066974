import { Dropdown, DropdownOption } from '@o4c/plugin-components';
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import uploaderStyle from "./fileUploader.module.scss"
import { UploaderFileData } from '../../interfaces';
import classNames from 'classnames';
import { uploadConfig } from '../uploaderContainer/uploadConfig';
import { useAlertStateContext } from '../../services/AlertProvider';
import IconImage from '../icons/IconImage';
import FileCard from '../fileCard/FileCardComponent';
import { useTranslation } from 'react-i18next';

const FILE_EXTENSION_REGEX = /^.*(?<fileExtension>\..*)$/;

const isAllowedFile = (file: any) => {
    const fileName = file.name;
    const { groups } = fileName.match(FILE_EXTENSION_REGEX) || {};
    return (
        [...uploadConfig.ALLOWED_DOC_EXTENSIONS, ...uploadConfig.ALLOWED_IMG_EXTENSIONS].indexOf(groups.fileExtension.toLowerCase()) !== -1
    );
};

const isImage = (file: any) => {
    const fileName = file.name;
    const { groups } = fileName.match(FILE_EXTENSION_REGEX) || {};
    return (
        uploadConfig.ALLOWED_IMG_EXTENSIONS.indexOf(groups.fileExtension.toLowerCase()) !== -1
    );
};

const fileListToUploaderFileData = (filesList: File[]) => {
    return filesList.map(file => ({
        url: URL.createObjectURL(file),
        name: (file as File).name,
        file: new File([file], (file as File).name, {
            lastModified: file.lastModified,
        }),
    }))
};

const validateFiles = (
    newFiles: FileList | UploaderFileData[],
    cb: (payload: string) => boolean,
    fileErrorMultipleTthreshold: string,
    fileSizeTthreshold: string,
    maxFilesNumber: number,
    oldFiles?: FileList | UploaderFileData[],
) => {
    const isValid =
        newFiles.length + (oldFiles?.length || 0) <=
        maxFilesNumber;
    if (!isValid) {
        return cb(fileErrorMultipleTthreshold)
    }

    const hasInvalidFileSize = Array.prototype.slice
        .call(newFiles)
        .some((file) => file.size >= uploadConfig.MAX_FILE_SIZE);
    if (hasInvalidFileSize) {
        return cb(fileSizeTthreshold)
    }

    const hasInvalidFileType = Array.prototype.slice
        .call(newFiles)
        .some((file) => !isAllowedFile(file));
    if (hasInvalidFileType) {
        return cb("file type not allowed")
    }

    return true;
};

interface IFileUploaderProps {
    loading: boolean;
    documentType: string;
    setDocumentType: Dispatch<SetStateAction<string>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    files: UploaderFileData[];
    setFiles: Dispatch<SetStateAction<UploaderFileData[]>>;
}

const FileUploader: FunctionComponent<IFileUploaderProps> = ({
    loading,
    documentType,
    setDocumentType,
    files,
    setLoading,
    setFiles,
}) => {
    const { t } = useTranslation();
    const alertContext = useAlertStateContext() as any;

    const maxFilesNumber = () => (documentType === "Med_Card" ? 1 : 2);
    const FILE_ERROR_MULTIPLE_THRESHOLD = `You are only allowed to upload ${maxFilesNumber() === 1 ? "1 file" : "2 files"} per form!`;
    const FILE_SIZE_THRESHOLD = "The file you tried to upload is too large. (Max file size: 20 MB)";

    const alertError = (payload: string): boolean => {
        alertContext.dispatch({
            type: "show",
            payload,
        });
        return false;
    };

    const setFilesAndDispatch = (
        oldFiles: UploaderFileData[],
        toAppendFiles?: UploaderFileData[],
    ): void => {
        const newFiles = toAppendFiles
            ? [...oldFiles].concat(toAppendFiles)
            : [...oldFiles];
        const isValid = validateFiles(
            newFiles,
            alertError,
            FILE_ERROR_MULTIPLE_THRESHOLD,
            FILE_SIZE_THRESHOLD,
            maxFilesNumber()
        );

        if (isValid) {
            setFiles(newFiles);
        }
    };

    const uploadFilesOnChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
    ): Promise<void> => {
        try {
            const newFiles = e.target.files;
            setLoading(true);

            if (
                !newFiles ||
                !validateFiles(
                    newFiles,
                    alertError,
                    FILE_ERROR_MULTIPLE_THRESHOLD,
                    FILE_SIZE_THRESHOLD,
                    maxFilesNumber(),
                    files,
                )
            ) {
                setLoading(false);
                return;
            }

            const convertedFileData: UploaderFileData[] =
                fileListToUploaderFileData(Array.from(newFiles));
            setFilesAndDispatch(files, convertedFileData);

            setLoading(false);
        } catch (error) {
            console.error(error);
            alertContext.dispatch({
                type: 'show',
                payload: "file upload error",
            });
            setLoading(false);
        }
    };

    const removeFile = (index: number): void => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFilesAndDispatch(newFiles);
    };

    const handleDropdown = (e: string): void => {
        setDocumentType(e);
        if (e === 'Med_Card' && files.length === 2) removeFile(1);
    };

    return (
        <div className={uploaderStyle.top}>
            <div className={uploaderStyle.text}>
                {t(
                    'Kindly submit compliance documents for verification.',
                )}
            </div>
            <Dropdown
                onChange={(e) => handleDropdown(e)}
                value={documentType}
                label='Document type'
                name='document_type'
                rev=""
                required
            >
                <DropdownOption label='Medical card' value='Med_Card' />
                <DropdownOption label="Driver's license" value='Driver_License' />
            </Dropdown>
            <div
                className={classNames(
                    uploaderStyle.fileUploader
                )}
            >
                <label
                    className={classNames(
                        uploaderStyle.imageUploadLabel,
                        'o4c', 'button', 'default', 'outline',
                        {
                            [uploaderStyle.imageUploadLabel_disabled]: loading || files.length === maxFilesNumber(),
                        }
                    )}
                    htmlFor="ImageUpload"
                >
                    <IconImage />
                    <div className={uploaderStyle.buttonText}>{t("click here to upload or take photos")}</div>
                </label>
                <input
                    id="ImageUpload"
                    type="file"
                    disabled={loading}
                    className={uploaderStyle.imageUploadInput}
                    onChange={uploadFilesOnChange}
                    multiple
                    onClick={(event: any) => {
                        (event.target || {}).value = null;
                    }}
                    accept="image/*, application/pdf"
                />
                <div className={uploaderStyle.uploadNote}>
                    {
                        documentType === "Med_Card"
                            ? "Reminder to register your medical card locally if your state requires it. Reach out to Site Management if you have any questions"
                            : "Please upload the front and back of your license. Make sure picture is clear and without flash"
                    }
                </div>
                <div className={uploaderStyle.filesContainer}>
                    {files?.map((file: UploaderFileData, index: number) => <FileCard key={`image_card-${index}`} removeFile={removeFile} file={file} index={index} isFileImage={isImage(file)} />)}
                    {Array.from(Array(maxFilesNumber() - files.length).keys()).map((p, i) => (
                        <div
                            key={`file-placeholder-${i}`}
                            className={uploaderStyle.filePlaceholder}></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FileUploader;
