import { FunctionComponent, useState } from 'react';
import uploaderContainerStyle from "./uploaderContainer.module.scss"
import { UploaderFileData } from '../../interfaces';
import Loading from '../loading/Loading';
import SendButton from '../sendButton/SendButton';
import FileUploader from '../fileUploader/FileUploaderComponent';

interface IFileUploaderContainerProps { }

const FileUploaderContainer: FunctionComponent<IFileUploaderContainerProps> = () => {
    const [documentType, setDocumentType] = useState<string>("Med_Card")
    const [loading, setLoading] = useState<boolean>(false);
    const [files, _setFiles] = useState<UploaderFileData[]>([]);

    return (
        <>
            <div className={uploaderContainerStyle.container}>
                <FileUploader loading={loading} documentType={documentType} setDocumentType={setDocumentType} files={files} setLoading={setLoading} setFiles={_setFiles} />
                <SendButton setLoading={setLoading} files={files} documentType={documentType} setFiles={_setFiles} />
            </div>
            {loading ? <Loading /> : null}
        </>
    );
};

export default FileUploaderContainer;