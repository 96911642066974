import React, { FunctionComponent } from "react";

const IconImage: FunctionComponent = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <g clipPath="url(#clip0_590_2406)">
                    <path
                        d="M8.53331 5.46662C8.5022 5.33891 8.4291 5.22535 8.32574 5.14415C8.22238 5.06294 8.09475 5.0188 7.96331 5.0188C7.83186 5.0188 7.70423 5.06294 7.60087 5.14415C7.49751 5.22535 7.42441 5.33891 7.39331 5.46662L6.33331 7.77995C6.32121 7.80538 6.30287 7.82733 6.28 7.84375C6.25712 7.86017 6.23046 7.87053 6.2025 7.87386C6.17454 7.87719 6.14619 7.87338 6.1201 7.86279C6.09401 7.85219 6.07103 7.83517 6.05331 7.81329L5.33331 6.93995C5.25554 6.8301 5.15066 6.74224 5.02889 6.68492C4.90711 6.6276 4.77257 6.60276 4.63835 6.61283C4.50414 6.6229 4.37481 6.66752 4.26294 6.74237C4.15108 6.81721 4.06049 6.91973 3.99997 7.03995L2.35997 9.79995C2.3294 9.85015 2.31265 9.90754 2.31141 9.9663C2.31018 10.0251 2.3245 10.0831 2.35293 10.1346C2.38135 10.186 2.42288 10.229 2.47329 10.2592C2.5237 10.2894 2.5812 10.3058 2.63997 10.3066H9.99997C10.0551 10.3075 10.1096 10.2946 10.1586 10.2692C10.2075 10.2437 10.2494 10.2066 10.2805 10.161C10.3115 10.1154 10.3308 10.0628 10.3365 10.0079C10.3423 9.95307 10.3343 9.89764 10.3133 9.84662L8.53331 5.46662Z"
                        fill="#6B6B6B"
                    />
                    <path
                        d="M2.64001 3.66659C2.64001 4.02021 2.78049 4.35935 3.03054 4.60939C3.28059 4.85944 3.61973 4.99992 3.97335 4.99992C4.32697 4.99992 4.66611 4.85944 4.91616 4.60939C5.16621 4.35935 5.30668 4.02021 5.30668 3.66659C5.30668 3.31296 5.16621 2.97382 4.91616 2.72378C4.66611 2.47373 4.32697 2.33325 3.97335 2.33325C3.61973 2.33325 3.28059 2.47373 3.03054 2.72378C2.78049 2.97382 2.64001 3.31296 2.64001 3.66659Z"
                        fill="#6B6B6B"
                    />
                    <path
                        d="M11.3333 12.6667C11.687 12.6667 12.0261 12.5262 12.2761 12.2761C12.5262 12.0261 12.6667 11.687 12.6667 11.3333V1.33333C12.6667 0.979711 12.5262 0.640573 12.2761 0.390524C12.0261 0.140476 11.687 0 11.3333 0L1.33333 0C0.979711 0 0.640573 0.140476 0.390524 0.390524C0.140476 0.640573 0 0.979711 0 1.33333L0 11.3333C0 11.687 0.140476 12.0261 0.390524 12.2761C0.640573 12.5262 0.979711 12.6667 1.33333 12.6667H11.3333ZM1.33333 1.66667C1.33333 1.57826 1.36845 1.49348 1.43096 1.43096C1.49348 1.36845 1.57826 1.33333 1.66667 1.33333H11C11.0884 1.33333 11.1732 1.36845 11.2357 1.43096C11.2982 1.49348 11.3333 1.57826 11.3333 1.66667V11C11.3333 11.0884 11.2982 11.1732 11.2357 11.2357C11.1732 11.2982 11.0884 11.3333 11 11.3333H1.66667C1.57826 11.3333 1.49348 11.2982 1.43096 11.2357C1.36845 11.1732 1.33333 11.0884 1.33333 11V1.66667Z"
                        fill="#6B6B6B"
                    />
                    <path
                        d="M16 6.1533C16.0309 5.80417 15.9232 5.4569 15.7 5.18663C15.588 5.05072 15.4503 4.93833 15.2947 4.85594C15.139 4.77356 14.9687 4.72281 14.7933 4.70663L14.1267 4.64663C13.9508 4.63185 13.7762 4.68741 13.6412 4.80114C13.5063 4.91486 13.4219 5.07747 13.4067 5.2533C13.3955 5.34145 13.4021 5.43094 13.4261 5.5165C13.45 5.60206 13.4909 5.68198 13.5461 5.75154C13.6014 5.8211 13.67 5.87891 13.748 5.92158C13.8259 5.96424 13.9116 5.99089 14 5.99996H14.3333C14.3769 6.00329 14.4193 6.01532 14.4582 6.03535C14.497 6.05538 14.5314 6.08299 14.5594 6.11656C14.5874 6.15012 14.6083 6.18896 14.621 6.23077C14.6337 6.27258 14.6379 6.31651 14.6333 6.35996L13.9067 14.36C13.9033 14.4038 13.8913 14.4465 13.8713 14.4857C13.8514 14.5249 13.8239 14.5597 13.7905 14.5882C13.757 14.6167 13.7182 14.6383 13.6764 14.6518C13.6345 14.6652 13.5905 14.6703 13.5467 14.6666L4.58665 13.82C4.41074 13.8033 4.23539 13.8571 4.09914 13.9696C3.96288 14.0821 3.87686 14.2441 3.85998 14.42C3.84327 14.5959 3.89708 14.7712 4.00959 14.9075C4.12209 15.0437 4.28409 15.1298 4.45998 15.1466L13.7933 15.9933C14.1451 16.0248 14.495 15.9154 14.7662 15.6892C15.0375 15.463 15.2078 15.1384 15.24 14.7866L16 6.1533Z"
                        fill="#6B6B6B"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_590_2406">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default IconImage;
