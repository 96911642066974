import { FunctionComponent } from 'react';
import fileCardStyle from "./fileCard.module.scss"
import { UploaderFileData } from '../../interfaces';
import IconDelete from '../icons/IconDelete';
import IconAttachment from '../icons/IconAttachment';

interface IFileCardProps { file: UploaderFileData, index: number, removeFile: (index: number) => void, isFileImage: boolean }


const FileCard: FunctionComponent<IFileCardProps> = ({ file, index, removeFile, isFileImage }) => {

    const fileExtensionIndex = file?.name.lastIndexOf(".")
    const fileNameText = file?.name.substring(0, fileExtensionIndex)
    const fileExtension = file?.name.substring(fileExtensionIndex)

    return (
        <div className={fileCardStyle.wrapper}>
            {isFileImage ? (
                <div className={fileCardStyle.imageItem}>
                    <button
                        className={fileCardStyle.removeButton}
                        onClick={() => {
                            removeFile(index);
                        }}>
                        <IconDelete />
                    </button>
                    <img src={file.url} alt={file.name} />
                </div>
            ) : (
                <div className={fileCardStyle.docItem}>
                    <button
                        className={fileCardStyle.removeButton}
                        onClick={() => {
                            removeFile(index);
                        }}>
                        <IconDelete />
                    </button>
                    <IconAttachment />
                    <div
                        className={fileCardStyle.fileName}
                        placeholder={file.name}>
                        <span className={fileCardStyle.fileNameText}>{fileNameText}</span>
                        <div className={fileCardStyle.fileExtension}>{fileExtension}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileCard;