import loadingStyle from "./Loading.module.scss";

function Loading(props: any) {
	return (
		<div className={loadingStyle.loadingWrapper}>
			<div
				className={`${loadingStyle.loadingIcon} bg-primary`}>
				{/* divs for circle animation */}
				<div className={loadingStyle.circleDiv}></div>
				<div className={loadingStyle.circleDiv}></div>
				<div className={loadingStyle.circleDiv}></div>
				<div className={loadingStyle.circleDiv}></div>
			</div>
			{props.children && (
				<span
					className={`${loadingStyle.loadingChildren} bg-primary`}>
					{props.children}
				</span>
			)}
		</div>
	);
}

export default Loading;
